(function () {
    'use strict';

    angular.module('aerosApp')
        .controller('TraceViewer', TraceViewer);

    TraceViewer.$inject = ['$scope', '$state', '$stateParams', '$window', 'routesConstant'];

    function TraceViewer($scope, $state, $stateParams, $window, routesConstant) {

        var CUSTOMER = routesConstant.CUSTOMER;

        angular.extend($scope, {
            backToResults: backToResults,
            gotoLinkmap: gotoLinkmap
        });
        $scope.fiberName = $stateParams.fiberName;
        $scope.fiber = $stateParams.fiberId;


        function backToResults() {
            var paramsObj = {
                testType: 'OTDR',
                id: $stateParams.id,
                fiberGroup: $stateParams.fiberGroupId
            };
            $window.history.back();
        }

        function  gotoLinkmap() {
            var paramsObj = {
                testType: 'OTDR',
                resultType: "all",
                id: $stateParams.id,
                qry: "id=" + $stateParams.fiberGroupId,
                linkmap: +$stateParams.fiberId,
                fiberGroup: $stateParams.fiberGroupId
            };

            $state.go(CUSTOMER.PROJECTS_PROJECT_RESULTS.stateName, paramsObj, true);
        }

    }
})();
